<template>
  <div class="tc-regulate">
    <!-- 顶部功能 -->
    <div class="header-tool">
      <div class="authorityimg" @click="showAddAuthority">
        <img src="@/assets/images/newBtn.png" height="40" alt="" />
      </div>
      <!-- <div class="del">
        <img src="@/assets/images/del.png" alt />
      </div>-->
      <div class="refresh" @click="refresh()">
        <img src="@/assets/images/refresh.png" alt="" />
      </div>
    </div>
    <!-- 表格 -->
    <div class="content">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        stripe
        border=""
        class="tableBox"
      >
        <el-table-column
          type="selection"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="role_name"
          label="权限名称"
          width="400"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="note"
          label="备注"
          width="400"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="plain"
              @click="delPermitList(scope.row.id)"
              >删除</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button
              size="mini"
              type="plain"
              @click="showAddAuthority(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 页码 -->
    <div class="page">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="1"
        :page-size="15"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <!-- 编辑框 -->
    <!-- <el-dialog title="管理权限编辑" :visible.sync="dialogFormVisible">
      <h2>当前用户：{{uName}}</h2>
      <el-tree
        :data="data"
        show-checkbox
        node-key="route"
        ref="tree"
        highlight-current
        :props="defaultProps"
      ></el-tree>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitDialog(2)">确 定</el-button>
      </div>
    </el-dialog>-->
    <!-- 添加权限 / 编辑 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="boxTitle"
      :visible.sync="addAuthority"
      width="40%"
      center
      @close="hideAddAdminBox"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="96px"
        size="mini"
      >
        <el-form-item label="权限名称：" prop="role_name">
          <span v-if="hideName">{{ form.role_name }}</span>
          <el-input v-model="form.role_name" v-if="!hideName"></el-input>
        </el-form-item>
        <el-form-item label="管理范围：">
          <el-checkbox-group v-model="form.department_scope">
            <el-checkbox
              :label="item.id"
              name="type"
              v-for="item in departmentList"
              :key="item.id"
              >{{ item.name }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="列表权限：">
          <el-button @click="innerVisible = true">设置权限树</el-button>
          <!-- 内层 -->
          <el-dialog
            :close-on-click-modal="false"
            width="30%"
            title="设置权限树"
            :visible.sync="innerVisible"
            append-to-body
            @open="hideTreeBox"
          >
            <el-tree
              :data="data"
              show-checkbox
              node-key="route"
              ref="tree"
              highlight-current
              :props="defaultProps"
              :check-strictly="ischeckStrictly"
            ></el-tree>
            <span slot="footer" class="dialog-footer">
              <el-button @click="innerVisible = false">取 消</el-button>
              <el-button type="primary" @click="submitDialog">确 定</el-button>
            </span>
          </el-dialog>
        </el-form-item>
        <!-- <el-form-item label="权限类型：">
          <el-radio-group v-model="form.permit_type">
            <el-radio label="1">仅对所属单位的用户有权限</el-radio>
            <el-radio label="0">对所有用户有权限</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="测评权限：">
          <el-radio-group v-model="form.permit_review">
            <el-radio label="1">允许设置用户测评权限</el-radio>
            <el-radio label="0">禁止设置用户测评权限</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="量表权限：">
          <el-radio-group v-model="form.permit_measure">
            <el-radio label="1">允许设置所有量表权限</el-radio>
            <el-radio label="0">仅对部分量表有权限</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item label="备注：">
          <el-input type="textarea" v-model="form.note"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addAuthority = false">取 消</el-button>
        <el-button type="primary" @click="AddAuthorityBtn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  loadListPermit,
  getPermit,
  getPermitTree,
  addPermit,
  delPermit,
  updatePermit,
} from "@/api/evaluate.js";
import { loadMenu, loadDepartmentData } from "@/api/comm.js";
import $ from "jquery";
import { log } from "util";
export default {
  inject: ["reload"],
  data() {
    return {
      // ***********检验**********
      rules: {
        role_name: [
          { required: true, message: "请输入权限名称", trigger: "blur" },
        ],
      },
      // *************************
      ischeckStrictly: false,
      innerVisible: false,
      departmentList: [],
      tableData: [],
      // 页码
      page: 1,
      size: 15,
      // 总数
      total: 0,
      multipleSelection: "",
      dialogFormVisible: false,
      form: [],
      formLabelWidth: "120px",
      //   -----------
      data: [
        {
          id: 23,
          name: "qiyue-lin",
          sort: 0,
          path: null,
          parent_id: 0,
          children: [
            {
              id: 25,
              name: "qiyue-lin2",
              sort: 0,
              path: "23",
              parent_id: 23,
            },
            {
              id: 26,
              name: "miss",
              sort: 0,
              path: "23",
              parent_id: 23,
              children: [
                {
                  id: 4,
                  name: "you",
                  sort: 0,
                  path: "23,26",
                  parent_id: 26,
                  children: [
                    {
                      id: 27,
                      name: "love",
                      sort: 0,
                      path: "23,26,4",
                      parent_id: 4,
                      children: [
                        {
                          id: 28,
                          name: "forever",
                          sort: 0,
                          path: "23,26,4,27",
                          parent_id: 27,
                          children: [
                            {
                              id: 29,
                              name: "never",
                              sort: 0,
                              path: "23,26,4,27,28",
                              parent_id: 28,
                              children: [],
                            },
                            {
                              id: 30,
                              name: "forget",
                              sort: 0,
                              path: "23,26,4,27,28",
                              parent_id: 28,
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 21,
          name: " 字节跳动 开发部222",
          sort: 20,
          path: null,
          parent_id: 0,
          children: [],
        },
        {
          id: 1,
          name: "技术部门",
          sort: 19,
          path: null,
          parent_id: 0,
          children: [],
        },
      ],
      defaultProps: {
        children: "children",
        label: "title",
      },
      //   用户名
      uName: "",
      permit_url: [],
      addAuthority: false,
      // ------添加模态框-------
      form: {
        department_scope: [],
        permit_review: "1",
        permit_type: "0",
        permit_measure: "1",
        permit_url: ["/home"],
        note: "",
        role_name: "",
        id: 0,
      },
      // 修改模态框
      boxTitle: "新增权限",
      hideName: false,
      authorityId: 0,
    };
  },
  created() {
    this.loadListPermitData();
    // 获取树状权限
    getPermitTree().then((res) => {
      //console.log(res);
      this.data = res.data.permit_tree;
    });
    // 获取所有部门
    loadDepartmentData().then((res) => {
      this.departmentList = res.data.data;
      //console.log("departmentList", this.departmentList);
    });
  },
  methods: {
    // 刷新
    refresh() {
      this.reload();
    },
    // 页码监听
    handleCurrentChange(val) {
      // 根据page加载对应数据
      this.page = val;
      this.loadListPermitData(val);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 加载页面数据
    loadListPermitData(page) {
      let data = {
        size: this.size,
        page: page || 1,
      };
      loadListPermit(data).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.data;
      });
    },
    // 编辑
    edit(id) {
      // 发送请求 id
      getPermit({ id }).then((res) => {
        //console.log(res);
        this.uName = res.data.role_name;
        this.permit_url = res.data.permit_url;
        //console.log(this.permit_url);
        // this.$refs.tree.setCheckedKeys([
        //   "/home",
        //   "/evaluate",
        //   "/user_evaluate",
        //   "/measure",
        //   "/interview",
        //   "/permit",
        //   "/psychological_census"
        // ]);
        this.$refs.tree.setCheckedKeys(this.permit_url);
      });
      //  弹出模态框
      this.dialogFormVisible = true;
    },
    // 获取
    getCheckedKeys() {
      //console.log(this.$refs.tree.getCheckedKeys());
    },
    // 设置
    setCheckedKeys() {
      this.$refs.tree.setCheckedKeys(["/psychological_census"]);
    },
    // 清空
    resetChecked() {
      this.$refs.tree.setCheckedKeys();
    },
    // 点击确定获得权限路径
    submitDialog(val) {
      this.innerVisible = false;
      // 勾选
      var a = this.$refs.tree.getCheckedKeys();
      // 半选
      var b = this.$refs.tree.getHalfCheckedKeys();
      this.form.permit_url = a.concat(b);
    },
    // 显示添加权限框 / 编辑
    showAddAuthority(row) {
      this.boxTitle = "新增权限";
      this.hideName = false;
      this.addAuthority = true;
      //console.log(" this.form.department_scope", this.departmentList);
      if (row.id) {
        this.boxTitle = "编辑权限";
        this.hideName = true;
        let id = row.id;
        this.form.id = row.id;
        // 获取数据渲染
        getPermit({ id }).then((res) => {
          //console.log(res);
          let {
            department_scope,
            role_name,
            permit_type,
            permit_review,
            permit_measure,
            permit_url,
            note,
          } = res.data;
          this.form.permit_type = permit_type + "";
          this.form.permit_review = permit_review + "";
          this.form.role_name = role_name;
          this.form.permit_measure = permit_measure + "";
          this.form.permit_url = permit_url;
          this.form.note = note;
          this.form.department_scope = department_scope.map(Number);
        });
      }
    },
    // 添加权限
    AddAuthorityBtn() {
      let arr = JSON.parse(JSON.stringify(this.form.department_scope));
      this.form.department_scope = this.form.department_scope.toString();
      let isCode = true; //控制是否表单验证通过
      this.$refs.form.validate((valid) => {
        if (!valid) {
          this.$message({
            showClose: true,
            message: "温馨提醒，还有内容未填写",
            type: "warning",
          });
          isCode = false;
          return;
        }
      });
      //控制是是否结束请求
      if (!isCode) {
        this.form.department_scope = arr;
        return;
      }
      if (!this.hideName) {
        // 添加
        let data = this.form;
        addPermit(this.form).then((res) => {
          if (res.code === 400200) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            this.addAuthority = false;
            this.boxReset();
            this.loadListPermitData(this.page);
          } else {
            this.form.department_scope = arr;
          }
        });
      } else {
        // 修改
        updatePermit(this.form).then((res) => {
          //console.log("修改", this.hideName);
          if (res.code === 400200) {
            console.log(2);
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.addAuthority = false;
            this.boxReset();
            this.loadListPermitData(this.page);
          } else {
            this.form.department_scope = arr;
          }
        });
      }
    },
    // 重置模态框
    boxReset() {
      this.form.department_scope = [];
      this.form.permit_review = "1";
      this.form.permit_type = "0";
      this.form.permit_measure = "1";
      this.form.permit_url = ["/home"];
      this.form.note = "";
      this.form.role_name = "";
    },
    // 模态框关闭回调
    hideAddAdminBox() {
      this.boxReset();
    },
    // 删除
    delPermitList(id) {
      this.$confirm("此操作将永久该权限, 是否继续?", "提示", {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delPermit({ id }).then((res) => {
            if (res.code === 400200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.loadListPermitData(this.page);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 树形权限模态框渲染数据
    hideTreeBox() {
      //console.log("我打开了");
      this.$nextTick(() => {
        if (this.boxTitle == "新增权限") {
          this.$refs.tree.setCheckedKeys(["/get_menu_list"]);
        } else {
          // this.$refs.tree.setCheckedKeys(this.form.permit_url);
          this.form.permit_url.forEach((i, n) => {
            var node = this.$refs.tree.getNode(i);
            if (node.isLeaf) {
              this.$refs.tree.setChecked(node, true);
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="less">
.tc-regulate {
  //   头部工具
  .header-tool {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .del {
      width: 120px;
      margin-right: 20px;
      cursor: pointer;
    }
    .refresh {
      width: 100px;
      cursor: pointer;
    }
    .authorityimg {
      cursor: pointer;
      width: 150px;
      margin-right: 20px;
      a {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    img {
      width: 100%;
      pointer-events: none;
    }
  }
  //   表格
  .content {
    position: relative;
    height: calc(~"100% - 220px");
    min-height: 740px;
    margin-top: 20px;
    color: rgb(4, 87, 102);
    th {
      color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      background: rgb(217, 242, 247);
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
      margin-left: 0px;
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: rgb(4, 87, 102);
      font-size: 14px;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    .el-input__inner {
      width: 50px;
      padding: 5px;
      text-align: center;
    }
  }
  // 页码
  .page {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
}
</style>